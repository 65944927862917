import React from 'react';

import { FormattedMessage } from 'gatsby-plugin-intl';
import ToDownload from 'components/ToDownload';

import './SupportPage.scss';

const files = [{
  id: 'toDownload-1',
  descId: 'cadFormulaA',
  src: '/files/eho_formula_a.dwg',
  lang: 'all',
}, {
  id: 'toDownload-2',
  descId: 'cadFormulaB',
  src: '/files/eho_formula_b.dwg',
  lang: 'all',
}, {
  id: 'toDownload-3',
  descId: 'cadSyntaxA',
  src: '/files/eho_syntax_a.dwg',
  lang: 'all',
}, {
  id: 'toDownload-4',
  descId: 'guide2x2',
  src: '/files/guide_2x2.pdf',
  lang: 'all',
}, {
  id: 'toDownload-5',
  descId: 'guide3x1',
  src: '/files/guide_3x1.pdf',
  lang: 'all',
}, {
  id: 'toDownload-6',
  descId: 'guide2x1',
  src: '/files/guide_2x1.pdf',
  lang: 'all',
}];

const SupportPage = () => (
  <div className="support-page">
    <div className="line" />
    <h2 className="title">
      <FormattedMessage id="supportPageTitle" />
    </h2>
    <div className="section-wrapper">
      <div>
        <div className="left">
          <div className="line" />
          <h2>
            <FormattedMessage id="supportPageSubtitle" />
          </h2>
          <div className="description-text">
            <FormattedMessage id="supportPageSubDesc" />
          </div>
        </div>
        <div className="right">
          <img src="/images/eho07293_s.jpg" alt="" className="img-responsive" />
        </div>
      </div>
    </div>
    <div className="to-download-wrapper">
      <ToDownload files={files} />
    </div>
  </div>
);

export default SupportPage;
